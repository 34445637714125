@import "variables";
@import "reset";
@import "ui";

#site-header:not(.header-transparent) .inner {
    padding-bottom: 5px;
}

#site-header {
    position: relative;
    z-index: 99999;
    $nav-spacing: 25px;
    align-items: center;
    background-color: $accent;
    color: $white;
    font-size: $text-l;
    font-family: $font-secondary;

    .logo {
        margin-right: 50px;
    }

    &.water-effect { 
        margin-bottom: 30px; 
        > .inner { transform: translateY(14px); }
    }
    
    &.header-transparent { background-color: transparent; transform: translateY(30%); }
    &:not(.header-transparent).header-responsive .nav-right { padding-bottom: 50px; }

    .header-ext {
        background-color: $white;
    }

    > .inner {
        position: relative;
        padding: 12px $base-padding;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    nav a.active {
        border-bottom: 2px solid white;
    }

    nav a:not(.button) {
        padding: 5px 0;

        &:hover { text-decoration: none; border-bottom: 2px solid white; }
    }

    .nav-left {
        margin-right: auto;

        nav a { margin-right: $nav-spacing; }
    }

    .nav-right {
        margin-left: auto;
    }
}

.header-responsive {
    #header-toggle {
        display: block;
    }

    &.menu-collapsed .nav-right {
        display: none;
    }

    &.menu-collapsed .nav-left {
        display: none;
    }
}

#header-toggle {
    position: absolute;
    display: none;
    right: 0;
    top: 0;
    font-size: 26px;
    background: none;
    color: white;
    border: none;
    outline: none;
    margin: 25px;
    cursor: pointer;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: rgba(0, 0, 0, 0.1);
    border: 2px solid transparent;

    &:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }

    &.active {
        background-color: rgba(0, 0, 0, 0.3);
        border-color: $primary;
    }
}


#site-footer {
    position: relative;
    background-color: #222;
    margin-top: 120px;

    .logo {
        display: block;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .upper {
        max-width: $screen-size-large;
        margin: 0 auto;
        padding: $base-padding;
    }

    .lower { background-color: #444; }
    .lower > .inner {
        padding: 20px;
        color: #fff;
        display: flex;
        align-items: center;

        & > * { margin: 0; }

        .logo {
            margin-right: 40px;
        }

        nav a { margin-right: 15px; color: $neutral-mid; }

        .social-tray a { 
            color: $primary; 

            &:hover { color: lighten($primary, 25%); }
        }
    }

    address {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        color: #ccc;
        font-size: $text-s;
    }

    .site-identity {
        color: $primary;
        border-top: 1px solid #555;
    
        nav { display: flex; gap: 15px; }
    }
}

#subscribe-newsletter {
    position: relative;
    max-width: 950px;
    top: -80px;
    background-color: $accent;
    color: white;
    padding: 40px 40px;
    border-radius: 14px;
    margin-bottom: -40px;

    .thumbnail-wrapper img {
        max-width: 250px;
        fill: green;
        margin: -30px 0 -40px 15px !important;
    }

    .button-status { padding: 10px 0; }

    .input-wrapper {
        position: relative;

        button { 
            position: absolute;
            top: 0px;
            color: $primary;
            transform: rotateZ(-30deg);
            right: 5px;
            font-size: 28px;
            background: none;
            outline: none;
            border: none;
            cursor: pointer;

            &:hover { color: darken($primary, 10%); }
            &.button-loading {
                padding: 14px;
            }
        }
    }

    input { background-color: rgba(0, 0, 0, 0.3); color: white; }
    input::placeholder { color: rgba(255, 255, 255, 0.5); }
    input:focus { border-color: $primary; }
}

#booking-form {
    
    .inner {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 20px;
        max-width: 600px;
    }
}

.logo {

    img {
        max-width: 120px;
        margin-top: 6px;
    }

    &.logo-text {
        font-family: Sketch;
        font-size: 112px;
        font-weight: normal;
    }

}

.auth-wrapper {
    display: flex;
    min-height: 100vh;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;

    .side-content {
        width: 100%;
        background-color: $primary;
        padding: $base-padding;
    }   

    .main-content {
        position: relative;
        min-width: 600px;
        background-color: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $base-padding;

        .close {
            position: absolute;
            right: 0;
            top: 0;
            padding: 60px;
            font-size: 46px;
            line-height: 0;
        }
    }
}

.page-home {

    .hero-section {
        position: relative;
        background-color: $accent;
        background-image: url(../images/resort/outdoor-1-transformed.jpeg);
        background-size: cover;
        background-position: center;
        margin-bottom: -65px;
        padding-bottom: 140px;
        padding-top: 120px;
        margin-top: -100px;
        color: $white;
    
        >.image-content {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    .section-separator {
        position: absolute;
        min-height: 100px;
        background-image: url(../images/waves-white.svg);
        bottom: 0;
        z-index: 5;
        width: 100%;
    }

}

.page-booking {
    

}

#events-showcase .image-content-container {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 50px;

    .image-content {
        border-radius: 10px;
        justify-content: space-between;
        flex-wrap: wrap;

        .image { min-width: 270px; }
        img { border-radius: 10px; }
    }

}

#testimonials-showcase {

    .image {
        max-width: 180px;
        margin-bottom: 10px;
    }

    .testimonial-container {
        background-color: #eee;
        padding: 40px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        gap: 30px;
    }

    .title-wrapper {
        margin-right: 40px;
    }

    .testimonial {
        max-width: 360px;

        .testimonial-header { margin-bottom: 20px; display: flex; align-items: center;}
        .author { display: flex; flex-direction: column; justify-content: flex-start; }
        .author-rating { margin-bottom: -5px; }
        .author-pfp { background: url(../images/pfp.jpg); background-size: 100% auto; width: 95px; height: 95px; border-radius: 100px; margin-right: 20px; border: 3px solid $blue; }
        .author-name { font-weight: bold; }
        .author-job { color: #888; }
    }

}

.page-coming-soon {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: -50px;

    img { max-width: 300px; min-width: 300px; }
}

.services-showcase {
    margin-top: 50px;
    display: grid;
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
    gap: 30px;

    .service-item {
        cursor: default;
        border-radius: 10px;
        background: #eee;
        overflow: hidden;
    }

    .image-content .content {  padding: 20px; }
    .image-content .image { line-height: 0; }
    .image-content img { max-width: 260px; width: 100; min-width: 220px; }
}

.site-policy {
    h5 { margin-bottom: 5px; }
    h6 { margin-top: 20px; }

    .left-indent {
        margin-left: 40px;
    }
}

.page-contact {
    
    > section {
        display: grid;
        grid-template-columns: minmax(300px, 1fr) minmax(250px, 300px);
        align-items: start;
        gap: 30px;
    }

    .title-wrapper i { margin-top: -40px; font-size: clamp(64px, 10vw, 100px); color: $blue; margin-left: 20px; }
}

.page-about {
    
}

@media screen and (max-width: 800px) {

    #site-header {

        > .inner {
            position: relative;
            background-color: $accent;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 0;
            margin-bottom: -15px;
        }

        &.water-effect > .inner { transform: none; }
        .nav-left nav { display: flex; gap: 20px; flex-direction: column; }

        .nav-left nav a { 
            margin-right: 0; 
            text-align: center; 
            border-bottom: 2px solid transparent; 
            &.active { border-color: white; }
        }

        .nav-right { margin: 0; width: 100%; margin-top: 25px; }
        .nav-right .button-primary { max-width: 100%; width: 100%; display: block; }
        .nav-left { margin: 0; }
        .logo { margin: 0 auto 15px 0; }
    }

    #site-header.header-transparent {
        transform: none;
    }

    .page-contact > section {
        grid-template-columns: 1fr;
        align-items: center;
    }

    .page-home .hero-section > .image-content {
        margin-top: 0;
    }

    .grid-span2 { grid-column: span 1;}

    #booking-form .inner {
        max-width: 100%;
        grid-template-columns: 1fr;
    }

    .fieldgroup:last-child {
        margin-bottom: 0;
    }

    form {
        margin-bottom: 20px;

        .button-secondary {
            width: 100%;
        }
    }

    .block-list {
        flex-direction: row;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;

        .block-list-item { min-width: 200px; max-width: 250px; }
    }

    .page-coming-soon {
        flex-direction: column !important;
        text-align: center;
    }

    #testimonials-showcase .testimonial-container {
        flex-wrap: wrap;
    }

    #subscribe-newsletter {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;

        .thumbnail-wrapper img {
            margin-left: 0 !important;
            margin-bottom: -30px !important;
        }        
    }

    .icon-box-container {
        flex-direction: column;
        align-items: center;
    }

    #events-showcase .image-content > .image-wrapper {
        max-width: 100%;
        width: 100%;
        margin-bottom: 15px;
    }

    .page-home .hero-section {
        margin-bottom: 0;
    }

    #site-footer .lower > .inner .logo {
        margin-right: 20px;
    }
    
    #site-footer .logo img {
        max-width: 100px;
    }

    form .button-submit { margin-right: 0; }
}

@media screen and (max-width: 1000px) {
    .services-showcase {
        grid-template-columns: 1fr;

        .image-content {
            flex-direction: column;

            .image { max-width: 100%; width: 100%; }
            img { max-width: 100%; width: 100%; height: auto; border-radius: 10px; }
        }
    }

    .page-booking .image-content {
        flex-direction: column-reverse !important;
        .image-wrapper { margin-top: 20px; }
    }
}