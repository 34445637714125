* {
    box-sizing: border-box;
}

body, html {
    margin: 0;
    padding: 0;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);

    font-family: $font-primary;
    font-size: 16px;
    color: #0d0d0d;
    background: $white;
    overflow-x: hidden;
}

p { margin: 0; font-family: $font-secondary; }
input, button, textarea, select { font-family: $font-secondary; }

a { 
    color: unset; 
    text-decoration: none; 
    transition: color $ms-color;

    &:hover { text-decoration: underline; }
}

img { max-width: 100%; height: auto; }

h1 { font-size: clamp(26px, 5vw, 46px); }
h2 { font-size: clamp(22px, 4vw, 40px); }
h3 { font-size: clamp(18px, 3vw, 34px); }
h4 { font-size: clamp(16px, 3vw, 28px); }
h5 { font-size: clamp(16px, 3vw, 24px); }
h6 { font-size: clamp(16px, 3vw, 20px); }

.text-xl { font-size: clamp(26px, 5vw, 46px); }

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 10px;
    font-family: $font-secondary;
    line-height: 1.2;
}

hr { border: 0.5px solid $primary; border-radius: $ui-radius-large; }

/* Helpers */
.padding-l { padding: $padding-l; }
.base-padding { padding: $base-padding; }
.section { margin-top: clamp(20px, 5vw, 100px); margin-bottom: clamp(40px, 5vw, 100px); }
.section:last-child { margin-bottom: 0; }

.ss-1 { max-width: $screen-size-small; margin-left: auto; margin-right: auto; }
.ss-2 { max-width: $screen-size-medium; margin-left: auto; margin-right: auto; }
.ss-3 { max-width: $screen-size-large; margin-left: auto; margin-right: auto; }

.nm { margin: 0 !important; }
.ml-auto { margin-left: auto !important; }
.mr-auto { margin-right: auto !important; }
.mt-auto { margin-top: auto !important; }
.mb-auto { margin-bottom: auto !important; }

.mt-1 { margin-top: 10px; }
.mt-2 { margin-top: 15px; }
.mt-3 { margin-top: 20px; }
.mt-4 { margin-top: 25px; }
.mt-5 { margin-top: 50px; }

.mr-1 { margin-right: 10px; }
.mr-2 { margin-right: 15px; }
.mr-3 { margin-right: 20px; }
.mr-4 { margin-right: 25px; }
.mr-5 { margin-right: 50px; }

.mb-1 { margin-bottom: 10px; }
.mb-2 { margin-bottom: 15px; }
.mb-3 { margin-bottom: 20px; }
.mb-4 { margin-bottom: 25px; }
.mb-5 { margin-bottom: 50px; }

.fg-1 { gap: 20px; }
.fg-2 { gap: 40px; }
.fg-3 { gap: 60px; }

.text-center { text-align: center; }
.text-faded { color: $neutral-mid; }
.icon-wrapper { font-size: 36px; }

.soft-shadow {
    box-shadow:
        0px 0px 1.8px rgba(0, 0, 0, 0.008),
        0px 0px 4.1px rgba(0, 0, 0, 0.011),
        0px 0px 7.4px rgba(0, 0, 0, 0.014),
        0px 0px 12.4px rgba(0, 0, 0, 0.016),
        0px 0px 20.4px rgba(0, 0, 0, 0.019),
        0px 0px 35.6px rgba(0, 0, 0, 0.022),
        0px 0px 77px rgba(0, 0, 0, 0.03)
    ;
}

/* Major Layouts */
.grid-span2 { grid-column: span 2; }
.flex { display: flex; align-items: center; }
.flex-apart { display: flex; justify-content: space-between; align-items: center; }
.flex-stretch { align-items: stretch !important; }
.flex-column { display: flex; flex-direction: column; justify-content: flex-start; }