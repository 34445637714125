@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&family=Titillium+Web&display=swap');
@font-face {
    font-family: Sketch;
    src: url(./fonts/Sketch.ttf);
}

$primary: #ff8800;
$accent: #ad0d22;
$blue: #00aeff;
$tertiary: #180044;

$neutral-light: #f5f5f5;
$neutral-dark: #181818;
$neutral-mid: #999;
$white: #fff;
$black: #0d0d0d;

$text-s: clamp(14px, 2vw, 16px);
$text-m: clamp(14px, 3vw, 18px); 
$text-l: clamp(16px, 4vw, 20px); 

$font-primary: 'Titillium Web', sans-serif;
$font-secondary: 'Poppins', sans-serif;

$screen-size-small: 600px;
$screen-size-medium: 1100px;
$screen-size-large: 1400px;

$base-padding: 20px;
$padding-l: clamp($base-padding, 5vw, 70px);
$ms-color: 0.1s;

/* UI */
$ui-radius: 10px;
$ui-radius-large: 14px;