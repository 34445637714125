.custom-marker {
    position: relative;
    display: inline-block;
    margin-left: 70px;

    span::before {
        position: absolute;
        content: '';
        width: 50px;
        height: 50px;
        left: -60px;
        top: -15px;

        background-image: url(../images/marker-spiral.svg);
        background-size: 100% auto;
    }
}

.button {
    position: relative;
    display: inline-block;
    color: $white;
    font-size: $text-s;
    text-decoration: none !important;
    transition: background-color $ms-color, color $ms-color;
    padding: 14px 40px;
    display: inline-block;
    border-radius: 50px;
    text-align: center;
    border: 2px solid $white;
    font-family: $font-secondary;
    cursor: pointer;
}

.button.form-submit {
    margin-top: 10px;
    width: 100%;
    cursor: pointer;
}

.button-primary {
    background: $primary;
    border-color: transparent;
    &:hover { border-color: white; }
}

.button-loading {     
    display: flex;
    justify-content: space-between;
    text-align: center;

    span { margin-right: 15px; }
}

.button-status i { font-size: 20px; color: rgb(0, 221, 0); margin-right: 10px; }

.button-loading::after {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background-image: url(../images/loader-round-white.svg);
    background-size: 100% auto;
    margin-right: -10px;
}

.button-secondary {
    background-color: $accent;
    color: $white;
    font-weight: bold;
    &:hover { background-color: darken($accent, 5%); color: $white; }
}

.button-tertiary { 
    width: 100%; 
    color: $accent; 
    font-family: $font-secondary; 

    &:hover { text-decoration: underline !important; }
}

.fieldgroup {
    margin-bottom: 20px;

    label span { display: inline-block; margin-bottom: 5px; font-family: $font-secondary; }
}

.form-title {
    margin-bottom: 30px;
}

.subtitle {
    font-family: $font-primary;
    font-weight: normal;
    font-size: $text-m;
    margin-bottom: 2px;
}

.link { font-weight: bold; color: $primary; }

/* Inputs */
input[type='text'], input[type='email'], input[type='date'], input[type='password'], select, textarea { font-size: 16px; background-color: rgba(0, 0, 0, 0.075); border-radius: 10px; border: 2px solid transparent; outline: none; padding: 10px; width: 100%; min-width: 150px; font-weight: bold; }
input[type='text']:focus, input[type='email']:focus, input[type='date']:focus, input[type='password']:focus, select:focus, textarea:focus { border-color: $primary; }
input[type='text']::placeholder, input[type='email']::placeholder, input[type='date']::placeholder, input[type='password']::placeholder, select::placeholder, textarea::placeholder { color: rgba(0, 0, 0, 0.3); }

textarea { min-height: 120px; height: 150px; }

input[type='checkbox'] { width: 22px; height: 22px; margin-right: 10px; }
.checkbox-wrapper { display: flex; align-items: center; }

/* Widget: Image Content */
.image-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > * {
        /* padding: $padding-l; */
    }

    > .content {
        max-width: 700px;
    }

    > .image-wrapper {
        min-width: 35%;
        width: 45%;
    }
}

/* Widget: BlockList */
.block-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;

    .block-list-item {
        color: white;
        background: $accent;
        border-radius: 10px;
        min-width: 270px;
        width: 100%;
        min-height: 200px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: background-color $ms-color;
        margin-bottom: 30px;

        i { font-size: clamp(36px, 3vw, 84px); margin-bottom: 25px; }
        &:hover { background-color: darken($accent, 5%); }
    }
}

/* Widget: Themed Container */
.themed-container {
    background-color: #eee;
    padding: 20px;
    border-radius: 10px;

    .subtitle { 
        text-transform: uppercase;
    }

    .inner {
        max-width: 700px;
        margin: 60px auto;
    }
}

/* Widget: IconBox */
.icon-box-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .icon-box {
        max-width: 300px;
        border-radius: 10px;
        padding: 20px;

        strong {
            font-size: $text-m;
            display: block;
            margin-bottom: 10px;
        }
    }
}